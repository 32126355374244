@import "styles/index"

.positionChildElementsByHalves
    //each child will take half of the width minus 8px (for the gap) and on mobile will take the whole width
    @apply [&>*]:flex-50 xsm:[&>*]:max-w-[calc(50%-8px)]

.bottomSheetCustom
    max-width: 600px!important
    overflow: hidden!important
    border-top-left-radius: 28px
    border-top-right-radius: 28px

    mat-bottom-sheet-container
        width: 100vw!important
        min-width: 0!important
        max-width: 600px!important
        padding: 0 16px 16px
