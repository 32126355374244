mat-button-toggle-group
    @apply h-8 rounded-lg #{!important}

.mat-button-toggle-group-appearance-standard:hover,
.mat-button-toggle-appearance-standard:hover
    @apply font-semibold bg-primaryLight #{!important}

.mat-button-toggle-appearance-standard.mat-button-toggle-disabled:hover
    @apply bg-white #{!important}
.mat-button-toggle-appearance-standard.mat-button-toggle-disabled.mat-button-toggle-checked:hover
    @apply bg-primarySecondary #{!important}

.mat-button-toggle-appearance-standard.mat-button-toggle-checked
    @apply font-semibold text-white bg-accentContrastLight rounded-lg #{!important}

.andOrToggle .mat-button-toggle-group .mat-button-toggle-appearance-standard.mat-button-toggle-checked
    @apply bg-secondaryMain #{!important}

.mat-button-toggle-appearance-standard.mat-button-toggle-disabled
    @apply text-primarySecondary #{!important}

.andOrToggle .mat-button-toggle-group .mat-button-toggle-appearance-standard.mat-button-toggle-checked.mat-button-toggle-disabled,
.mat-button-toggle-appearance-standard.mat-button-toggle-checked.mat-button-toggle-disabled
    @apply text-white bg-primarySecondary #{!important}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content
    @apply py-[3px] px-3 leading-6 #{!important}

.mat-button-toggle-group-appearance-standard .mat-button-toggle-appearance-standard+.mat-button-toggle-appearance-standard
    @apply border-none #{!important}

.mat-button-toggle-focus-overlay
    @apply hidden

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard, .mat-button-toggle-group-appearance-standard
    @apply border-primaryLight #{!important}
