.mat-expansion-panel
    @apply shadow-none #{!important}

.mat-expansion-panel.advancedOptions
    .mat-expansion-panel-header
        @apply bg-primaryBackground #{!important}
        &:hover
            .mat-expansion-panel-header-title
                @apply text-primaryDark #{!important}

.mat-expansion-panel-header
    @apply h-fit bg-white text-sm font-semibold text-primaryDark #{!important}
    &:hover
        @apply bg-white #{!important}
        .mat-expansion-panel-header-title
            @apply text-secondaryMain #{!important}

.mat-content.mat-content-hide-toggle
    @apply m-0 #{!important}

.mat-expansion-panel-body
    @apply p-0 #{!important}
