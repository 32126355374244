@tailwind base
@tailwind components
@tailwind utilities

@import "global"
@import "components"

@import "./theme/variables"
@import "./theme/shadows"

@layer base
    body
        @apply text-primaryDark
        font-size: 16px
        font-family: "Poppins", sans-serif
