//mat-option state bg colors
html
    --mat-option-hover-state-layer-color: var(--background)!important
    --mat-option-focus-state-layer-color: var(--background)!important
    --mat-option-selected-state-layer-color: var(--background)!important

.mat-mdc-select
    @apply w-fit text-primaryDark #{!important}

.mat-mdc-select-panel
    @apply py-1.5 px-1 my-1 rounded-lg #{!important}

.mat-mdc-option
    @apply py-1 px-2 my-0.5 min-h-[20px] rounded-sm box-content #{!important}

.mat-mdc-option span
    @apply py-1 px-2 my-0.5 h-5 min-h-0 rounded-sm box-content leading-4 #{!important}

//placeholder
.mat-mdc-select-placeholder
    @apply relative text-primarySecondary text-sm z-10 #{!important}

//select + options text
.mat-mdc-select,
.mat-mdc-option
    @apply relative text-primaryDark text-sm z-10 #{!important}

//select custom icon
.mat-mdc-select-arrow
    @apply invisible #{!important}

.mat-mdc-form-field-flex
    @apply relative #{!important}

.mat-mdc-form-field-icon-suffix
    @apply absolute right-0 #{!important}

.mat-mdc-form-field-icon-suffix>.mat-icon.matSelectIconCustom
    @apply h-5 w-6 py-0 pr-3 pl-2 text-primarySecondary text-3xl leading-5 box-content #{!important}

//selected option
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
    @apply bg-white font-semibold #{!important}
    &:hover
        @apply bg-primaryBackground #{!important}
.mat-mdc-option .mat-pseudo-checkbox-minimal
    @apply hidden #{!important}
.mat-pseudo-checkbox
    @apply w-5 h-5 rounded-sm m-0 border border-solid border-primarySecondary hover:border-secondaryMainLight #{!important}

.mat-pseudo-checkbox-checked
    @apply bg-secondaryMain border-0 hover:bg-indigo-600 #{!important}
