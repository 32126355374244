.mdc-text-field
    @apply h-fit min-h-[40px] p-0 #{!important}

.mat-mdc-form-field-infix
    @apply min-h-fit px-3 py-[8px] #{!important}
.mat-mdc-form-field
    @apply w-full #{!important}

.mat-mdc-form-field-infix
    @apply w-fit min-h-fit pl-3 pr-6 py-[8px] #{!important}

//border-radius
.mdc-text-field
    --mdc-outlined-text-field-container-shape: 8px!important

//border colors
.mdc-text-field .mdc-notched-outline__leading,
.mdc-text-field .mdc-notched-outline__notch,
.mdc-text-field .mdc-notched-outline__trailing
    @apply border-primaryLight #{!important}

.mdc-text-field.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field.mdc-text-field--focused .mdc-notched-outline__trailing
    @apply border-secondaryMainLight #{!important}

.mdc-text-field.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing
    @apply border-red-200 #{!important}

//disabled bg
.mdc-text-field.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field.mdc-text-field--disabled .mdc-notched-outline__trailing
    @apply bg-primaryBackground #{!important}

//hint & error msg
.noHintError .mat-mdc-form-field-subscript-wrapper
    @apply hidden #{!important}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper
    @apply p-0 #{!important}

.mat-mdc-form-field-hint
    @apply text-primarySecondary

//placeholder
.mdc-text-field .mdc-text-field__input::placeholder
    @apply relative text-primarySecondary text-sm z-10 #{!important}

//text
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input
    @apply relative text-primaryDark text-sm z-10 #{!important}

//prefix icon
.mat-mdc-form-field-icon-prefix
    @apply pl-3 pr-0 #{!important}
    .mat-icon
        @apply h-5 w-fit text-primarySecondary text-xl leading-5 p-0 #{!important}

//suffix icon
.mat-mdc-form-field-icon-suffix
    @apply pr-3
    .mat-icon
        @apply p-0 h-4 w-4 text-base leading-4 text-red-500


