\:root
    --tb-primary: #536DFE
    --tb-primary-dark: #3F51B5
    --tb-primary-light: #8C9EFF

    --tb-secondary: #FF6E40
    --tb-secondary-dark: #E64A19
    --tb-secondary-light: #FF9E80

    --tb-text: #06134A
    --tb-text-light: #9DA9D5
    --tb-text-inversed: #FFFFFF

    --tb-neutral-4: #D1D6EC
    --tb-neutral-3: #F1F3FC
    --tb-neutral-2: #FAFAFA
    --tb-neutral-1: #FFFFFF

    // remove usage of these colors
    --primary-light: #E8EAF6
    --primary-secondary: #9da9d5
    --primary-main: #282A5F
    --primary-dark: #06134A

    --secondary-light: #8C9EFF
    --secondary-main: #536DFE

    --contrast-light: #FF9E80
    --contrast-main: #FF6E40

    --background: #FAFAFA
