//needs to be removed
.text-center
    text-align: center

.text-muted
    color: rgba(0, 0, 0, 0.57)

.text-size-xxs
    font-size: 11px
    line-height: 13px

.text-size-xs
    font-size: 14px
    line-height: 15px

.text-size-sm
    font-size: 16px
    line-height: 16px

.text-size-md
    font-size: 18px
    line-height: 18px

.text-upper
    text-transform: uppercase

.fs-lg
    font-size: large

.fs-md
    font-size: medium

.fs-lg-21
    font-size: 21px

.rounded
    border-radius: 50%

.rounded-sm
    border-radius: 4px

.circle-sm
    width: 20px
    height: 20px

.c-pointer
    cursor: pointer

.text-light
    color: rgba(255, 255, 255, 0.87)

.text-white
    color: #fff

.text-default
    color: rgba(0, 0, 0, 0.87)

.text-warning
    color: #FFB300

.text-primary
    color: #0D47A1

    &:hover
        color: #1E88E5

.text-success
    color: #2E7D32

.d-block
    display: block

.d-flex
    display: flex

.flex-column
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start

.flex-start-center.justify-content-end,
.justify-content-end
    justify-content: flex-end

.justify-content-center
    justify-content: center

.justify-content-between
    justify-content: space-between

.align-items-start
    align-items: flex-start

.float-end
    align-self: flex-end

.float-right
    float: right

.float-left
    float: left

.position-relative
    position: relative

.position-sticky
    position: sticky

.position-fixed
    position: fixed

.b-light
    border: 1px solid #cccccc

.bl-light
    border-left: 1px solid #cccccc

.mat-flat-button.no-padd-btn
    min-width: 36px
    padding: 0

.mt-xs
    margin-top: 5px

.mt-sm
    margin-top: 10px

.mt-md
    margin-top: 15px

.mt-lg
    margin-top: 20px

.mt-xxl
    margin-top: 25px

.mr-xs
    margin-right: 5px

.mr-sm
    margin-right: 10px

.mr-md
    margin-right: 15px

.mr-lg
    margin-right: 20px

.mr-xxl
    margin-right: 25px

.mb-xs
    margin-bottom: 5px

.mb-sm
    margin-bottom: 10px

.mb-md
    margin-bottom: 15px

.mb-lg
    margin-bottom: 20px

.mb-xxl
    margin-bottom: 25px

.mb-0
    margin-bottom: 0


.ml-xs
    margin-left: 5px

.ml-sm
    margin-left: 10px

.ml-md
    margin-left: 15px

.ml-lg
    margin-left: 20px

.ml-xxl
    margin-left: 25px

.ml-auto
    margin-left: auto !important

.mr-auto
    margin-right: auto !important

.p-l-r-sm
    padding: 0 15px

.p-0
    padding: 0

.px-2
    padding-left: 10px
    padding-right: 10px

.py-2
    padding-top: 10px
    padding-bottom: 10px

.p-2
    padding: 10px

.pr-sm
    padding-right: 20px

.w-100
    width: 100%

.w-50
    width: 50%

.w-30
    width: 30%

.w-80
    width: 80px

.h-50px
    height: 50px

.h-100
    height: 100%

.b-r
    border-right: 1px solid #ccc

.flex-start-center
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center

    &.justify-content-between
        justify-content: space-between

    &.justify-content-and
        justify-content: flex-end

    &.align-items-start
        align-items: flex-start


.flex-start-center.text-center
    justify-content: center


.badge
    padding: 1px 5px
    border-radius: 12px
    border: 1px solid #ccc
    line-height: 14px
    font-size: 14px
