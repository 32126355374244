.mat-mdc-checkbox.small,
.mat-mdc-checkbox.small .mdc-checkbox,
.mat-mdc-checkbox.small .mdc-checkbox__background,
.mat-mdc-checkbox.small .mdc-checkbox__native-control,
.mat-mdc-checkbox.small .mat-mdc-checkbox-touch-target
    @apply w-4 h-4 min-w-[16px] #{!important}

// size of all main checkbox elements
.mdc-checkbox, .mdc-checkbox__native-control, .mat-mdc-checkbox-touch-target
    @apply h-5 w-5 min-w-[20px] p-0 #{!important}

mat-checkbox > .mdc-form-field
    @apply mb-1 #{!important}

.mat-mdc-checkbox
    @apply h-5 w-5 border border-solid border-primarySecondary hover:border-secondaryMainLight rounded-sm #{!important}

.mat-mdc-checkbox-checked
    @apply box-content border-none #{!important}

// background
.mdc-checkbox__background
    @apply h-5 w-5 border-none rounded-sm #{!important}

// background positioning
.mdc-checkbox .mdc-checkbox__background
    @apply top-0 left-0 #{!important}

// tick svg styling
.mdc-checkbox__background > svg
    @apply h-3 w-3 m-auto #{!important}

// hiding extra effects
.mat-mdc-checkbox-ripple, .mdc-checkbox__ripple
    @apply hidden #{!important}
