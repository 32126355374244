.bottomSheetCustom
    max-width: 600px!important
    overflow: hidden!important
    border-top-left-radius: 28px
    border-top-right-radius: 28px

    mat-bottom-sheet-container
        width: 100vw!important
        min-width: 0!important
        max-width: 600px!important
        padding: 0 16px 16px
